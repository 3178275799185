:root {
  --primary-clr: #00B507;
  --secondary-clr: #FF8A00;
  --title-text-clr: #000;
  --neutral-text-clr: #ffffff;
  --body-text-clr: #202020;
  --blue-clr: #0035BE;
  --bg-1: #FFFEEE;
  --bg-2: #F4FFF5;
  --bg-3: #FAFDFF;
  --bg-4: #FFFEEE;
  --bg-5: #070030;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  background-color: var(--neutral-text-clr);
  color: var(--title-text-clr);
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
li {
  list-style-type: none;
  display: inline-block;
}

a {
  text-decoration: none;
  color: #0046F9;
}

img {
  width: 100%;
  height: 100%;
}

button {
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
}

.container {
  margin: auto;
  width: 80%;
}

.btn {
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  color: var(--blue-clr);
  padding: 9px 17px 10px 18px;
}

.btn-outline {
  border: 1px solid var(--blue-clr);
}

.btn-filled {
  color: var(--neutral-text-clr);
  background-color: var(--blue-clr);
}

.btn-wrapper {
  display: flex;
  gap: 22px;
}

/* -- STATUS BAR -- */
.statusbar {
  background-color: var(--secondary-clr);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.statusbar-wrapper {
  margin-left: 10%;
  max-width: -moz-max-content;
  max-width: max-content;
  width: 80%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

#menu {
  background-color: none;
  display: none;
}

/* -- NAVBAR -- */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 104px;
}

.logo {
  height: 102px;
  width: 102px;
}

.nav-items {
  gap: 55px;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 55px;
}

.nav-link {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: var(--title-text-clr);
}

.active {
  font-weight: 600;
  padding: 40px 20px;
  border-bottom: 1px solid #000;
  background: rgba(0, 0, 0, 0.04);
}

/* -- HERO -- */
.hero {
  height: 70vh;
  background: url("/public/assets/images/hero_image.svg"), lightgray 0px -332.059px/100% 164.404%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-container {
  width: 996px;
  margin: auto;
  color: var(--neutral-text-clr);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.highlight-primary {
  color: var(--primary-clr);
}

.hero-title {
  color: var(--neutral-text-clr);
  text-shadow: 0px 0px 30px #000;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.hero-subtitle {
  width: 646px;
  height: 52px;
  margin-top: 10px;
  color: #FFF;
  text-align: center;
  text-shadow: 0px 0px 30px #000;
  font-family: Inter;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.join-now-btn {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 2px;
  padding: 6px 12px;
  margin-top: 51px;
  background: #FFF;
}

/* -- ABOUT -- */
.about {
  background-color: var(--bg-1);
  padding-block: 49px;
}

.about-wrapper {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.about-wrapper>.col-1 {
  width: 52vw;
  padding-bottom: 35px;
}

.about-title {
  color: #000;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  text-transform: uppercase;
}

.about-wrapper>.col-2 {
  width: 400px;
  height: 524px;
  position: relative;
}

.about-image {
  background: url("../public/assets/images/about_image.svg"), lightgray 50%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-content {
  margin-top: 35px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

.play-btn {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

/* -- GALLERY -- */
.gallery {
  padding-block: 3.5rem;
  height: 441px;
  background-color: var(--bg-2);
}

.gallery-title {
  text-align: center;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 50px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 260px !important;
  /* gap: 27px; */
}

.swiper-slide img {
  width: 205px;
  height: 260px;
}

/* -- ARTICLE -- */
.article {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-block: 8rem;
}

.article-title {
  text-align: center;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 49px;
}

/* -- CONTACT -- */
.contact {
  padding-block: 45px;
  background-color: var(--bg-4);
}

.contact-title {
  text-align: center;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.address {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding-block: 45px;
  flex-wrap: wrap;
}

.address-content {
  width: -moz-max-content;
  width: 563.002px;
}

.address-subtitle {
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #6F6F6F;
}

.address-line {
  border-bottom: 0.5px solid #6F6F6F;
  padding-block: 15px;
}

.address-line-content {
  width: 510px;
  height: 52px;
  color: #282828;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.address-line-subcontent {
  color: #282828;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.address-line-subcontent span {
  font-weight: 500;
}

.address-line-title {
  padding: 20px 0 10px 0;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.address-description {
  margin-top: 32px;
}

.message-btn {
  width: 150px;
  height: 42px;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  background: #D9D9D9;
  margin-top: 18px;
}

.map {
  width: 570px;
  height: 498px;
}

/* -- FOOTER -- */
.footer {
  --txt-clr: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-block: 2.5rem;
  background-color: #070030;
}

.footer .logo {
  width: 101px;
  height: 101px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.footer-content a {
  color: var(--txt-clr);
  text-decoration: underline;
  text-underline-offset: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.footer-description {
  color: var(--txt-clr);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.6;
  margin-top: 10px;
}

.responsive-menu {
  display: none;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .statusbar-wrapper {
    font-size: 10px;
    width: 90%
  }

  nav {
    height: 70px;
  }

  .logo,
  .nav-container .logo {
    width: 44px;
    height: 44px;
  }

  .btn-wrapper {
    gap: 10px;
  }

  .btn {
    font-size: 10px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    padding: 9px 16px;
  }

  .hero {
    height: 60vh;
  }

  .hero-container {
    width: 100%;
    max-height: 253px;
  }

  .hero-title {
    width: max-content;
    margin: auto;
    font-size: 27px;
    font-weight: 700;
  }

  .hero-subtitle {
    width: 276px;
    font-size: 14px;
    font-weight: 400;
  }

  .join-now-btn {
    margin-top: 20px;
  }

  /* ABOUT */
  .about {
    padding-block: 32px;
  }

  .about-wrapper>.col-1 {
    width: 90vw;
    padding-bottom: 25px;
  }

  .about-content {
    margin-top: 25px;
    font-size: 1rem;
  }

  .about-wrapper>.col-2 {
    width: 100%;
    height: 424px;
  }

  .address-content {
    width: 100%;
  }
  .address-line-content {
    width: 100%;
  }

  .message-btn {
    margin-top: 50px;
  }

  .map {
    width: 100%;
    height: 400px;
  }
}